<template>
  <div v-if="isLoading" class="text-center">
    <asom-icon icon="spinner" class="animate-spin" />
  </div>
  <div v-else class="space-y-4">
    <asom-card
      :title="$t('screens.cashManagement.Admin.Configuration.cutOffTime')"
      size="sm"
    >
      <div class="flex justify-between">
        <h1 class="font-bold text-3xl text-black">{{ cutOffTime }}</h1>
        <asom-button  v-if="canEditCMCutOffTime" icon="pen" @click="toggleCutoffTimeEdit(true)" />
      </div>
    </asom-card>
    <asom-card v-if="canViewCMConfiguration"
      :title="
        $t('screens.cashManagement.Admin.Configuration.stationCashThreshold')
      "
    >
      <asom-client-table
        :columns="[
          'line',
          'station',
          'total',
          'maxPSC',
          'minPSC',
          'maxAFC',
          'minAFC',
          'maxAFCDraft',
          'noofpsm',
          canEditCMCashThreshold && 'stationId',
        ]"
        :data="stationCashThresholds"
      >
        <template v-slot:header_line>
          {{ $t("screens.cashManagement.Admin.Configuration.line") }}
        </template>
        <template v-slot:header_station>
          {{ $t("screens.cashManagement.Admin.Configuration.station") }}
        </template>
        <template v-slot:header_total>
          {{ $t("screens.cashManagement.Admin.Configuration.total") }}
        </template>
        <template v-slot:header_maxPSC>
          {{ $t("screens.cashManagement.Admin.Configuration.maxPSC") }}
        </template>
        <template v-slot:header_minPSC>
          {{ $t("screens.cashManagement.Admin.Configuration.minPSC") }}
        </template>
        <template v-slot:header_maxAFC>
          {{ $t("screens.cashManagement.Admin.Configuration.maxAFC") }}
        </template>
        <template v-slot:header_minAFC>
          {{ $t("screens.cashManagement.Admin.Configuration.minAFC") }}
        </template>
        <template v-slot:header_maxAFCDraft>
          {{ $t("screens.cashManagement.Admin.Configuration.maxAFCDraft") }}
        </template>
        <template v-slot:header_noofpsm>
          {{ $t("screens.cashManagement.Admin.Configuration.noofpsm") }}
        </template>
        <template v-slot:header_stationId>{{ "" }}</template>
        <template v-slot:stationId="slotScoped">
          <edit-station-threshold-config
            :stationId="slotScoped.data"
            :refresh="this.loadConfig"
            :lineId="slotScoped.rowData.lineId"
          />
        </template>
      </asom-client-table>
    </asom-card>

    <asom-modal
      v-model="showEditCutoffTimeModal"
      title="Edit Cut-off Time"
      :dismissible="false"
    >
      <asom-alert v-if="error" :error-message="error" />
      <p class="pt-4">Please select a cut-off time</p>
      <asom-form-field
        v-if="cutOffTime"
        label="Cut-off Time"
        required
        :state="inputStates('formData.cutOffTime')"
        error="Please provide cut-off time"
      >
        <div class="flex justify-start">
          <div>
            <asom-input-date-time
              v-model="formData.cutOffTime"
              :state="inputStates('formData.cutOffTime')"
              :pickerMode="'time'"
            />
          </div>
          <div class="pl-2 self-center">
            <span class="italic">(hh:mm)</span>
          </div>
        </div>
      </asom-form-field>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="onConfirmCutoffTimeEdit"
          :disabled="disableCutoffTimeEditConfirmation"
          :loading="isLoading"
          text="Confirm"
        />
        <asom-button
          @click="toggleCutoffTimeEdit(false)"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import EditStationThresholdConfig from "./EditStationThresholdConfig.vue";
import { parseDateTime } from "@/helpers/dateTimeHelpers";
import { updateCutOffTime } from "@/services/cashManagement.service";

export default {
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      isLoading: false,
      showEditCutoffTimeModal: false,
      error: null,
      disableCutoffTimeEditConfirmation: false,
      formData: {
        cutOffTime: null,
      },
    };
  },
  components: {
    EditStationThresholdConfig,
  },
  validations() {
    return {
      formData: {
        cutOffTime: { required },
      },
    };
  },
  mounted() {
    this.loadConfig();
  },
  computed: {
    ...mapState({
      cutOffTime: (state) => state.cashManagement.configuration.cutOffTime,
      stationCashThresholds: (state) =>
        state.cashManagement.configuration.stationCashThresholds,
    }),
    ...mapGetters({
      canEditCMCutOffTime: 'auth/canEditCMCutOffTime',
      canEditCMCashThreshold: 'auth/canEditCMCashThreshold',
      canViewCMConfiguration: 'auth/canViewCMConfiguration'
    }),
  },
  methods: {
    async loadConfig() {
      this.isLoading = true;
      await this.$store.dispatch("cashManagement/configuration/getDataAsync");
      this.isLoading = false;
      this.formData.cutOffTime = parseDateTime(
        moment(this.cutOffTime, "HH:mm A").toDate()
      );
    },
    editStationCashThreshold(data) { // eslint-disabled
      console.log(data);
    },
    toggleCutoffTimeEdit(value) {
      this.error = null;
      this.showEditCutoffTimeModal = value;
    },
    async onConfirmCutoffTimeEdit() {
      this.error = null;
      this.isSubmitting = true;
      const result = await updateCutOffTime({
        cutOffTime: parseDateTime(this.formData.cutOffTime),
      });
      if (!result.success) {
        this.error = result.payload;
      } else {
        this.toggleCutoffTimeEdit(false);
        this.loadConfig();
      }
      this.isSubmitting = false;
    },
  },
};
</script>

<style></style>
